<template>
 
  <v-app style="background-color:#f8f9fb">

            <!-- Diálogo de progresso -->
  <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'" :produtos="tipos_funerarios"
      @filtrar="filterData" :title_opcao="'Situação'"
        ></filtro-component>
    </v-col>
  </v-container>

 



    <v-container style="background-color:#eee"  
    id="contrato"
    fluid
    tag="section">
    
    <v-row>

        <v-col
        cols="12"
        sm="6"
        lg="6">

        <base-material-stats-card-s elevation="1"
            color="green" 
            icon="mdi-sale"
            subIcon="mdi-information"
            subIconColor="green"
            title="Vendas Novas"
            :title_sub="'Planos'"            
            :sub-text="'Total de Vendas: '+ qtd_venda_nova"
            :valuex="this.bool_01"
            :value="formatarMoeda(this.soma_venda)"
            :downloadFunction="download_venda_plano_novo"
          />


        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="6">

        <base-material-stats-card-s elevation="1"
            color="green" 
            icon="mdi-sale"
            subIcon="mdi-information"
            subIconColor="green"
            title="Vendas Migração"
            :title_sub="'Planos'"            
            :sub-text="'Total de Vendas: '+ qtd_venda_migracao"
            :valuex="this.bool_04"
            :value="formatarMoeda(this.soma_venda_migracao)" 
            :downloadFunction="download_venda_plano_migracao"
          />


        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="6">

              <base-material-stats-card-s elevation="1"
              color="blue"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              :title="'Mensalidades Existente ('+this.selectedStartDate+' - '+this.selectedEndDate+') '"
              :title_sub="'Todos os Contratos'"            
              sub-icon-color="black"
              :valuex="this.bool_02"
              :value="formatarMoeda(this.soma_mensalidade_vencimento)"
              :sub-text="'Total de Mensalidades no Périodo: '+ this.qtd_mensalidade_vencimento"
              :downloadFunction="download_vendas_novas"

              />
        
        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="6">

        <base-material-stats-card-s elevation="1"
              color="blue"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              :title="'Mensalidades Existente ('+this.selectedStartDate+' - '+this.selectedEndDate+') '"
              :title_sub="'Contratos até ' + (new Date(this.selectedStartDate).getFullYear() - 1)"            
              sub-icon-color="black"
              :valuex="this.bool_03"
              :value="formatarMoeda(this.soma_mensalidade_vencimento_anterior)"
              :sub-text="'Total de Mens. no Périodo - Adesão até 31/12/ '+ (new Date(this.selectedStartDate).getFullYear() - 1)+': '+ this.qtd_mensalidade_vencimento_anterior"
              :downloadFunction="download_vendas_novas"

              />
        
        </v-col>



        <v-col
        cols="12"
        sm="6"
        lg="6">

        <base-material-stats-card-s elevation="1"
              color="blue"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              :title="'Mensalidades Existente ('+this.selectedStartDate+' - '+this.selectedEndDate+') '"
              :title_sub="'Contratos Migração em ' + (new Date(this.selectedStartDate).getFullYear() )"            
              sub-icon-color="black"
              :valuex="this.bool_05"
              :value="formatarMoeda(this.soma_mensalidade_vencimento_migracao_ano)"
              :sub-text="'Total de Mens. no Périodo - Migrados em '+ (new Date(this.selectedStartDate).getFullYear())+': '+ this.qtd_mensalidade_vencimento_migracao_ano"
              :downloadFunction="download_vendas_novas"

              />
        
        </v-col>

        <v-col
        cols="12"
        sm="6"
        lg="6">

        <base-material-stats-card-s elevation="1"
              color="blue"
              icon="mdi-barcode-scan"
              subIcon="mdi-information"
              subIconColor="blue"
              :title="'Mensalidades Existente ('+this.selectedStartDate+' - '+this.selectedEndDate+') '"
              :title_sub="'Contratos Migração em ('+this.selectedStartDate+' - '+this.selectedEndDate+') '"           
              sub-icon-color="black"
              :valuex="this.bool_06"
              :value="formatarMoeda(this.soma_mensalidade_vencimento_migracao_competencia)"
              :sub-text="'Total de Mens. no Périodo - Migrados em '+ this.selectedStartDate+' - '+this.selectedEndDate+': '+ this.qtd_mensalidade_vencimento_migracao_competencia"
              :downloadFunction="download_vendas_novas"

              />
        
        </v-col>



    </v-row>

  </v-container>

</v-app> 
</template>

<script>
import Acompanhamento from '../../services/acompanhamento'
import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto


  export default {

  name: 'Faturamento',
  
  components: {
    FiltroComponent,
  },
  
  created() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${month}/${year}`;
      this.selectedEndDate = `${day}/${month}/${year}`;
     
    },
    
  mounted() {

    this.dashboard();
   
    
  },
 
    data () {
      return {
       
        rules: {
            required: value => !!value || 'Obrigatório.',
          },

          soma_venda: 0,
          qtd_venda_nova: 0,

          soma_mensalidade_vencimento : 0,
          qtd_mensalidade_vencimento : 0,


          soma_mensalidade_vencimento_anterior : 0,
          qtd_mensalidade_vencimento_anterior : 0,

          soma_mensalidade_vencimento_migracao_ano : 0,
          qtd_mensalidade_vencimento_migracao_ano : 0,

          soma_mensalidade_vencimento_migracao_competencia : 0,
          qtd_mensalidade_vencimento_migracao_competencia : 0,


          soma_venda_migracao: 0,
          qtd_venda_migracao:0,


          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: ["Vencimento"],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais"],

          bool_01:true,
          bool_02:true,
          bool_03:true,
          bool_04:true,
          bool_05:true,
          bool_06:true,

          showProgress: false,



          tipos_funerarios: [
                "Todos",
              ],
        

          entregue: 0,
          nc: 0,

      }
  },

    methods: {



      formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },


       filterData(valores) {

        this.isLoading = true;
         let nova_consulta = false;
         this.bool_01 = true
          this.bool_02 = true
          this.bool_03 = true
          this.bool_04 = true
          this.bool_05 = true
          this.bool_06 = true

          this.qtd_mensalidade_vencimento_anterior = 0
          this.qtd_mensalidade_vencimento = 0


          this.soma_mensalidade_vencimento_migracao_ano = 0,
          this.qtd_mensalidade_vencimento_migracao_ano = 0,


          this.soma_mensalidade_vencimento_migracao_competencia = 0,
          this.qtd_mensalidade_vencimento_migracao_competencia = 0,


          
          this.qtd_venda_nova = 0


        
       
          if (this.selectedStartDate !== valores.selectedStartDate || this.selectedEndDate !== valores.selectedEndDate
          || this.selectedCompany !== valores.selectedCompany
          || this.selectedType !== valores.selectedType) {
            nova_consulta = true;
          }

          this.selectedType = 'Planos' //valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;



          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;


        if(nova_consulta){
          this.soma_venda = 0,
          this.dashboard();

        }

      },


      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

     
    calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },

      

      dashboard() {

              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 60 || diferencaEmDias < 0) {

                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }

                      Acompanhamento.soma_total_venda_nova(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_venda = response.data.soma_venda_nova;
                              this.qtd_venda_nova = response.data.qtd_venda_nova;
                            }
                          })
                          .catch(e => {
                            this.soma_venda = 0;
                            this.qtd_venda_nova = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_01 = false;
                          });

                        Acompanhamento.soma_mensalidade_periodo(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_mensalidade_vencimento = response.data.soma_mensalidade_vencimento;
                              this.qtd_mensalidade_vencimento = response.data.qtd_mensalidade_vencimento;
                            }
                          })
                          .catch(e => {
                            this.soma_mensalidade_vencimento = 0;
                            this.qtd_mensalidade_vencimento = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_02 = false;
                          });

                        Acompanhamento.soma_mensalidade_periodo_anterior(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_mensalidade_vencimento_anterior = response.data.soma_mensalidade_vencimento;
                              this.qtd_mensalidade_vencimento_anterior = response.data.qtd_mensalidade_vencimento;
                            }
                          })
                          .catch(e => {
                            this.soma_mensalidade_vencimento_anterior = 0;
                            this.qtd_mensalidade_vencimento_anterior = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_03 = false;
                          });

                          Acompanhamento.soma_total_venda_migracao(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_venda_migracao = response.data.soma_venda_migracao;
                              this.qtd_venda_migracao = response.data.qtd_venda_migracao;
                            }
                          })
                          .catch(e => {
                            this.soma_venda_migracao = 0;
                            this.qtd_venda_migracao = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_04 = false;
                          });

                          Acompanhamento.soma_mensalidade_migracao_ano(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_mensalidade_vencimento_migracao_ano = response.data.soma_mensalidade_vencimento;
                              this.qtd_mensalidade_vencimento_migracao_ano = response.data.qtd_mensalidade_vencimento;
                            }
                          })
                          .catch(e => {
                            this.soma_mensalidade_vencimento_migracao_ano = 0;
                            this.qtd_mensalidade_vencimento_migracao_ano = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_05 = false;
                          });

                        Acompanhamento.mensalidade_migracao_periodo(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                          .then(response => {
                            if (response.status === 200) {
                              this.soma_mensalidade_vencimento_migracao_competencia = response.data.soma_mensalidade_vencimento;
                              this.qtd_mensalidade_vencimento_migracao_competencia = response.data.qtd_mensalidade_vencimento;
                            }
                          })
                          .catch(e => {
                            this.soma_mensalidade_vencimento_migracao_competencia = 0;
                            this.qtd_mensalidade_vencimento_migracao_competencia = 0;
                          })
                          .finally(() => {
                            this.isLoading = false;
                            this.bool_06 = false;
                          });


                        

                          
      },

      download_venda_plano_novo() {

                this.showProgress = true;              
                const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
                const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
                const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


                if (diferencaEmDias > 60 || diferencaEmDias < 0) {

                    this.isLoading = false;
                    this.showFilter = true;
                    this.showProgress = false;
                    return 
                    }

                  Acompanhamento.download_venda_nova_xls(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
                  .then(response => {
                        if (response.status === 200) {
                        
                                const fileName = `venda_nova_${Date.now()}.xlsx`;
                                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                const fileLink = document.createElement('a')
                                fileLink.href = fileURL
                                fileLink.setAttribute('download', fileName)
                                document.body.appendChild(fileLink)
                                fileLink.click()
                            
                        }
                      })
                      .catch(error => {
                      }) .finally(() => {
                        this.showProgress = false;
                      });
      },

      download_venda_plano_migracao() {

        this.showProgress = true;              
        const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
        const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
        const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


        if (diferencaEmDias > 60 || diferencaEmDias < 0) {

            this.isLoading = false;
            this.showFilter = true;
            this.showProgress = false;
            return 
            }

          Acompanhamento.download_venda_migracao_xls(formattedStartDate, formattedEndDate, this.selectedCompany, 'Planos', 'Todos', 'Todas', 'Todos')
          .then(response => {
                if (response.status === 200) {
                
                        const fileName = `venda_migracao_${Date.now()}.xlsx`;
                        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                        const fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute('download', fileName)
                        document.body.appendChild(fileLink)
                        fileLink.click()
                    
                }
              })
              .catch(error => {
              }) .finally(() => {
                this.showProgress = false;
              });
        },

      download_vendas_novas(){    
        alert('em manutenção')
      },
      download_mensalide(){    
        alert('em manutenção 2')
      },
      download_mensalide_anterior(){    
        alert('em manutenção 3')
      },

    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }


</script>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }

  .custom-gray-background {
    height: 80px;
  }

  
  
  /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
  .fill-width {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }
  
  /* Centralize horizontalmente */
  .justify-center {
    justify-content: center;
  }

  .card-loader {
    height: 100%; /* Define a altura do content-loader como 100% para preencher o v-card */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  </style>
